import * as React from "react"
import { graphql, Link } from "gatsby"
import { Layout } from "../components/layout"
import { ProductListing } from "../components/product-listing"
import { Seo } from "../components/seo"
import {
  container,
  intro,
  callOut,
  callToAction,
  deployButton,
} from "./index.module.css"
import Logo from "../icons/logo"


function Hero(props) {
  return (
      <div className="md:w-1/2 pt-[15vh] md:pt-0 h-[80vh]  mx-auto  flex flex-row ">
        <Link to="/sport">
          <img src="/yoriboyblue.png" alt="YORI boy blue icon" className="mx-auto" />
        </Link>
        <Link to="/ysww">
          <img src="/yorigirlpink.png" alt="YORI girl pink icon" className="mx-auto" />
        </Link>
        <Link to="/mainline">
          <img src="/yoritrenchback.png" alt="YORI mainline icon" className="mx-auto" />
        </Link>
      </div>
  )
}

export default function IndexPage({ data }) {
  return (
    <Layout>
      <Hero />
      <ProductListing products={data?.shopifyCollection?.products} />
    </Layout>
  )
}

export const Head = () => <Seo />
